import Form from "../../components/form/Form";
import Front from "./components/front/Front";
import PortfolioHighlight from "./components/portfolioHighlight/portfolioHighlight";
import Section4 from "./components/section4/Section4";
import WhatWeDo from "./components/whatWeDo/WhatWeDo";
import './home.css'

export default function Home() {

  return (
    <div>
        <Front/>
        <PortfolioHighlight/>
        <WhatWeDo/>
        <Section4/>
        <Form/>
    </div>
  )
}
